<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        
          <v-card elevation="0">
            <v-card-title>
             
              <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.program.name"
                  label="Program"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.school.name"
                  label="School"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="pretestAssessment.assessment_tool"
                  label="Assessment Tool"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field

                  label="Assessment Date"
                  v-model="pretestAssessment._date"
                  readonly
                ></v-text-field
              ></v-col>
            </v-row>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="pretestLearners"
                :items-per-page="5"
                class="elevation-0"
              >  <template v-slot:top>
     
        <v-toolbar-title>Learners</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
    <postAssessmentDialog/>
      </v-toolbar>
    </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    color="primary"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn color="success" tile @click="savePreTest">
                  <v-icon>mdi-content-save</v-icon> Save
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn to="/assessmentlist" tile>
                  <v-icon>mdi-cancel</v-icon>Cancel</v-btn>
            </v-card-actions>
          </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sync,dispatch } from "vuex-pathify";
import postAssessmentDialog from './postAssessmentDialog.vue'

export default {
  // name: "PostAssessment",
  components:{
    postAssessmentDialog
  },

  data: () => ({
    headers: [
    { text: "Actions", value: "actions", sortable: "false" },
    { text: "PostTest Value", value: "post_value" },
      { text: "PostTest Level", value: "post_level" },
      { text: "Remarks", value: "Remarks" },
    { text: "Last Name", value: "learner.surname" },
      { text: "First Name", value: "learner.gname" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Type", value: "type" },
      { text: "PreTest Value", value: "_value" },
      { text: "PreTest Level", value: "_level" },
    
     
    ],
    preTestAssessmentForm:{
        program_id:'',
        school_id:'',
        assessment_tool:'',
        _date:''
      },
      preTestLearnersList:[],
  }),

  computed: {
    ...sync("assessment", ["el", "pretestAssessment","pretestLearners",'approvePreTest','postAssessmentDialog','postLearnerItem','postLearnerIndex']),
    ...sync("user",['userlogin']),
  },

  methods: {
    editItem(item) {
      this.postLearnerIndex=this.pretestLearners.indexOf(item)
      this.postLearnerItem=item;
      this.postAssessmentDialog=true;
    },

    async savePreTest() {
      await dispatch('assessment/savePostAssessment')
      await this.forApprovalPostTest()
      this.el=4
    },

    forApprovalPostTest(){
      this.approvePreTest.assessment_id=this.pretestAssessment.id
      this.approvePreTest._status='POSTTEST APPROVAL'
      this.approvePreTest.approved=false
      this.approvePreTest.users_id=this.userlogin.id
      dispatch('assessment/approvePretest')
    },

  },
};
</script>
